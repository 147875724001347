
import ExtraFuncionsMenuLinks from "~/mixins/extramenulink";
import { Component, Prop } from "nuxt-property-decorator";
import { MainMenu } from "~/types/components";

@Component
export default class TheMenuDropdown extends ExtraFuncionsMenuLinks {
  @Prop() mainMenu!: MainMenu[];

  mounted() {
    this.checkIfHasModalForm(this.mainMenu);
  }

  closeFormModal() {
    this.modalForm.visible = false;
  }
}
